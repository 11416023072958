import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generateRequestOptions, objectToQueryFormat } from '../../actions/action_helper';
import { SERVER_URL } from '../../lib/constants';

const mealPlansApi = createApi({
  reducerPath: 'mealPlansApi',
  baseQuery: fetchBaseQuery({ baseUrl: SERVER_URL }),
  endpoints: (builder) => ({
    getMealPlans: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `meal_plans?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
  }),
});

export const {
  useGetMealPlansQuery,
} = mealPlansApi;

export default mealPlansApi;
