import React from 'react';
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';

import { DAYS } from '../js/lib/constants';

const ToggleDays = ({ field, defaultDays, customColor }) => {
  return (
    <ToggleButtonGroup
      onChange={(event, value) => field?.onChange(value)}
      value={field?.value}
      sx={{
        '.MuiToggleButtonGroup-grouped': {
          'mr': 1,
          '&:not(:first-of-type)': {
            borderRadius: '50%',
            borderColor: customColor || 'secondary.main',
          },
          '&:first-of-type': {
            borderRadius: '50%',
            borderColor: customColor || 'secondary.main',
          },
        },
      }}
      aria-label="Days of the week"
    >
      {DAYS.map((day) => (
        <Tooltip
          key={day.value}
          title={!defaultDays?.includes(day.value) ? `Delivery unavailable on ${day.name}` : ''}
          placement="top"
          arrow
        >
          <div>
            <ToggleButton
              sx={{
                'height': 32,
                'textTransform': 'unset',
                'fontSize': '0.75rem',
                '&.Mui-selected, &.Mui-selected:hover': {
                  color: 'white',
                  backgroundColor: customColor || 'secondary.contrastText',
                  borderColor: customColor || 'secondary.contrastText',
                },
                '&.Mui-disabled': {
                  color: 'grey',
                  borderColor: 'grey',
                  backgroundColor: 'lightgrey',
                },
              }}
              disabled={!defaultDays?.includes(day.value)}
              value={day.value}
              aria-label={day.key}
            >
              {day.label}
            </ToggleButton>
          </div>
        </Tooltip>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleDays;