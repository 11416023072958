import React from 'react';
import {SnackbarProvider} from 'notistack';
import {ThemeProvider} from '@emotion/react';
import {Provider} from 'react-redux';
import {ErrorBoundary} from 'react-error-boundary';
import {CssBaseline} from '@mui/material';

import RoutingIndex from './routes';
import store from './store/store';
import {theme} from './theme';

function ErrorFallback({error}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{color: 'red'}}>{error.message}</pre>
    </div>
  );
}

export const App = () => <ThemeProvider theme={theme}>
  <Provider store={store}>
    <SnackbarProvider maxSnack={3} >
      <CssBaseline/>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <RoutingIndex />
      </ErrorBoundary>
    </SnackbarProvider>
  </Provider>
</ThemeProvider>;

export default App;
