import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {generateRequestOptions, objectToQueryFormat} from '../../actions/action_helper';
import { SERVER_URL } from '../../lib/constants';

const googleMapsApi = createApi({
  reducerPath: 'googleMapsApi',
  baseQuery: fetchBaseQuery({ baseUrl: SERVER_URL }),
  endpoints: (builder) => ({
    getPlaces: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: `places?${objectToQueryFormat(args)}`,
          method: 'GET',
        }),
      }),
    }),
    getPlaceById: builder.query({
      query: ({id}) => ({
        ...generateRequestOptions({
          url: `/places/${id}`,
          method: 'GET',
        }),
      }),
    }),
  }),
});

export const {
  useGetPlacesQuery,
  useGetPlaceByIdQuery,
  useLazyGetPlacesQuery,
  useLazyGetPlaceByIdQuery,
} = googleMapsApi;

export default googleMapsApi;
