import * as React from 'react';
import TextField from '@mui/material/TextField';
import Prototypes from 'prop-types';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker';
import {get} from 'react-hook-form';

export default function TimePicker({
  id,
  label,
  name,
  InputLabelProps,
  helperText,
  errors,
  required,
  size,
  ...rest
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileTimePicker
        id={id}
        label={label}
        slotProps={{
          textField: {
            fullWidth: true,
            helperText: get(errors, name)?.message || helperText,
            error: !!get(errors, name),
            size,
            required,
          },
        }}
        renderInput={(params) => <TextField
          {...params}
          InputLabelProps={{...InputLabelProps, shrink: true}}
          fullWidth
          label={label}
          InputProps={{
            ...params.InputProps,
          }}
        />}
        {...rest}
      />
    </LocalizationProvider>
  );
}

TimePicker.prototypes = {
  id: Prototypes.string.isRequired,
  label: Prototypes.string.isRequired,
  name: Prototypes.string.isRequired,
  required: Prototypes.bool.isRequired,
  options: Prototypes.array.isRequired,
};

TimePicker.defaultProps = {
  required: true,
  size: 'small',
  helperText: '',
};
