import * as React from 'react';
import Prototypes from 'prop-types';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import { TimeField as MuiTimeField} from '@mui/x-date-pickers/TimeField';
import {get} from 'react-hook-form';

export default function TimeField({
  id,
  label,
  name,
  InputLabelProps,
  helperText,
  errors,
  required,
  size,
  ...rest
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiTimeField
        id={id}
        label={label}
        slotProps={{
          textField: {
            fullWidth: true,
            helperText: get(errors, name)?.message || helperText,
            error: !!get(errors, name),
            size,
            required,
          },
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
}

TimeField.prototypes = {
  id: Prototypes.string.isRequired,
  label: Prototypes.string.isRequired,
  name: Prototypes.string.isRequired,
  required: Prototypes.bool.isRequired,
  options: Prototypes.array.isRequired,
};

TimeField.defaultProps = {
  required: true,
  size: 'small',
  helperText: '',
};
