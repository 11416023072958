import { configureStore } from '@reduxjs/toolkit';
import { apiSlices } from '../js/slices/index';

const { versionApi,
  mealPlansApi,
  leadsApi,
  googleMapsApi } = apiSlices;

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    [versionApi.reducerPath]: versionApi.reducer,
    [googleMapsApi.reducerPath]: googleMapsApi.reducer,
    [mealPlansApi.reducerPath]: mealPlansApi.reducer,
    [leadsApi.reducerPath]: leadsApi.reducer,

  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
    .concat(versionApi.middleware)
    .concat(mealPlansApi.middleware)
    .concat(googleMapsApi.middleware)
    .concat(leadsApi.middleware)
    .concat(googleMapsApi.middleware),
});

export default store;
