import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {generateRequestOptions} from '../../actions/action_helper';
import { SERVER_URL } from '../../lib/constants';

const versionApi = createApi({
  reducerPath: 'versionApi',
  baseQuery: fetchBaseQuery({ baseUrl: SERVER_URL }),
  endpoints: (builder) => ({
    getVersion: builder.query({
      query: (args) => ({
        ...generateRequestOptions({
          url: 'version',
          method: 'GET',
        }),
      }),
    }),
  }),
});

export const {useGetVersionQuery} = versionApi;
export default versionApi;
