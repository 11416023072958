import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Typography} from '@mui/material';
import {Container} from '@mui/system';

import ContentNotFoundImage from '../assets/images/content_not_found.svg';

export default function ContentNotFound() {
  const navigate = useNavigate();
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      <img src={ContentNotFoundImage} alt="content-not-found"/>
      <Typography variant='h5' fontWeight='bold'>Content Not Found</Typography>
      <Typography maxWidth="30%" fontSize={15} color="secondary.contrastText.main">The content you are looking for can&lsquo;t be found. Please try again</Typography>
    </Container>
  );
}
