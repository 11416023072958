import {TextField} from '@mui/material';
import React from 'react';
import Prototypes from 'prop-types';
import {get} from 'react-hook-form';

export default function BaseTextField({
  id,
  name,
  label,
  required = true,
  type,
  helperText,
  errors,
  validate,
  InputLabelProps,
  ...rest}) {
  return (
    <TextField
      InputLabelProps={{...InputLabelProps, shrink: true}}
      fullWidth
      required={required}
      id={id}
      type={type}
      label={label}
      error={!!get(errors, name)}
      helperText={get(errors, name)?.message || helperText}
      {...validate}
      {...rest}
    />
  );
}

BaseTextField.prototypes = {
  id: Prototypes.string.isRequired,
  label: Prototypes.string.isRequired,
  name: Prototypes.string.isRequired,
  required: Prototypes.bool.isRequired,
};
