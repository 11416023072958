import React from 'react';
import { Typography, Link } from '@mui/material';
import { TURMITECH_WEBSITE_URL, START_YEAR } from '../js/lib/constants';

export default function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary.contrastText" align="center" p={3}>
      Copyright © Dinespot Powered by{' '}
      <Link color="inherit" href={TURMITECH_WEBSITE_URL} target='_blank'>
        Turmitech
      </Link>{' '}
      Inc. All rights reserved. {`${START_YEAR}-${new Date().getFullYear()}`}.
    </Typography>
  );
}