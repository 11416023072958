import React from 'react';
import {Route, Routes, BrowserRouter} from 'react-router-dom';

import {
  LandingPage,
} from '../views/index';

const RoutingIndex = function() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutingIndex;
