import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';

export default function CoreButton({
  children,
  type,
  variant,
  fullWidth = true,
  color = 'primary',
  onClickHandler,
  isLoading = false,
  disabled = false,
  ...rest
}) {
  return (
    <LoadingButton
      type={type}
      fullWidth={fullWidth}
      loading={isLoading}
      variant={variant}
      onClick={onClickHandler}
      disabled={disabled}
      sx={{
        ...(disabled && {
          'color': '#C5C5C5',
          'backgroundColor': 'secondary.disabledBackground',
          'cursor': 'none',
        }),
        ...(!disabled && {
          'backgroundColor': 'secondary.contrastText',
        }),
        ':hover': {
          ...(disabled && {
            'color': '#C5C5C5',
            'backgroundColor': 'secondary.disabledBackground',
          }),
          ...(!disabled && {
            'backgroundColor': 'secondary.contrastText',
          }),
        },
        'm': 0,
      }}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
}

CoreButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary.contrastText']),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};
