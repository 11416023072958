import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { get } from 'react-hook-form';

export default function TuiAutoComplete({
  id,
  name,
  label,
  required = true,
  type,
  helperText,
  options = [],
  errors,
  loading = false,
  size = 'small',
  labelKey,
  InputLabelProps,
  ...rest }) {
  return (
    <Autocomplete
      autoHighlight
      id={id}
      loading={loading}
      getOptionLabel={(option) => option ? option[labelKey] : 'None'}
      isOptionEqualToValue={(option, value) => option ? option[labelKey] === value[labelKey] : value === null}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete='new-password'
          InputLabelProps={{ ...InputLabelProps, shrink: true }}
          fullWidth
          type={type}
          size={size === 'small' || size === 'medium' ? size : 'small'}
          label={label}
          required={required}
          error={!!get(errors, name)}
          helperText={get(errors, name)?.message || helperText}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
      {...rest}
    />
  );
}

TuiAutoComplete.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
};
